.logo {
  height: 50px;
  background-image: url("logo.png");
  margin: 16px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 7rem;
  background-position-y: center;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.site-layout {
  height: 100%;
}
.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

.page-title {
  margin-top: 0;
  font-size: large;
  text-align: left;
  margin-bottom: 3rem;
}
.ant-layout-sider-trigger {
  position: absolute !important;
}
